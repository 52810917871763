import http from "axios";
import store from "@/store";
http.interceptors.request.use(function (config) {
  config.baseURL =
    process.env === "production"
      ? "https://api.swiftpay.mv"
      : "http://localhost/api";
  const token = store.getters.getUser?.token;
  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
});

const errorHandler = (error) => {
  if (error.response.status === 401) {
    store.dispatch("LOGOUT"); // here is the problem
  }
  return Promise.reject(error);
};

http.interceptors.response.use(
  (response) => response,
  (error) => errorHandler(error)
);

class SwiftAPI {
  login(user) {
    return http.post("/auth/login", user);
  }
  getCustomers() {
    return http.get("/customer/get/all");
  }
  // eslint-disable-next-line no-unused-vars
  getTransactions(from, to) {
    var url = "/transactions/all?";
    if (from) {
      url = url + "from=" + from;
    }

    if (to) {
      url = url + "&to=" + to;
    }
    return http.get(url);
  }
  getTransaction(reference) {
    return http.get("/admin/transaction/" + reference);
  }
  getTodaySales() {
    return http.get("/admin/sales");
  }
  getTransactionsOfCustomer(id) {
    return http.get("/customer/find/" + id);
  }
  verifyTransaction(id) {
    return http.put("/transactions/" + id + "/verify");
  }
  refresh(refreshToken, fcmToken) {
    return http.post("/auth/refreshToken", {
      fcmToken: fcmToken,
      refreshToken: refreshToken,
    });
  }
  logout() {
    return http.put("/auth/logout");
  }
}
export default new SwiftAPI();
