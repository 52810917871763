<template>
  <div class="app-layout">
    <div>
      <Sidebar />
      <Navbar />
      <div class="content">
        <transition name="fade">
          <router-view></router-view>
        </transition>
        <notifications position="top right" />
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar";
import Navbar from "@/components/Navbar";
import firebase from "../firebase";
import SwiftAPI from "../services/SwiftAPI";

export default {
  components: {
    Sidebar,
    Navbar,
  },
  data() {},
  async created() {
    var token = await firebase.getToken(firebase.messaging);
    var result = await SwiftAPI.refresh(
      this.$store.state.user.refreshToken,
      token
    );

    firebase.onMessage(firebase.messaging, (payload) => {
      console.log(payload);
      this.$notify({
        title: payload.notification.title,
        text: payload.notification.body,
        duration: 10000,
      });

      var data = JSON.parse(payload.data["gcm.notification.data"]);
      this.$store.dispatch("NEW_TRANSACTION", data);
    });

    this.$store.dispatch("REFRESH_SUCCESS", result.data);
  },
};
</script>
<style lang="css">
.vue-notification {
  border: none !important;
  background: #ad1f23 !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.app-layout {
  background: #f2f2f2;
}

.heading {
  font-size: 20px;
  font-weight: bolder;
}

.content {
  min-height: 97vh !important;
  background: white !important;
  margin-left: 80px;
  border-radius: 20px;
  padding: 20px;
  margin-right: 10px;
}

/* On screens that are 768px wide or less, go from four columns to two columns */
@media screen and (max-width: 768px) {
  .content {
    min-height: 97vh !important;
    background: white !important;
    margin-left: 10px !important;
    border-radius: 20px;
    padding: 20px;
  }
}
</style>
