<template>
  <div class="login">
    <div class="centered">
      <form style="margin-top: 100px" @submit.prevent class="p-2">
        <input
          type="text"
          placeholder="Username"
          class="form-control"
          v-model="user.userName"
        />
        <input
          type="password"
          placeholder="Password"
          class="my-3 form-control"
          v-model="user.password"
        />
        <input
          v-show="require2fa"
          type="text"
          placeholder="One Time Passcode"
          class="my-3 form-control"
          v-model="otp"
        />
        <p class="text-danger">{{ error }}</p>
        <button
          @click="login"
          class="btn primary"
          type="button"
          :disabled="loading"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span v-show="!loading" class="sr-only">Login</span>
        </button>

        <p class="mt-5 copyright">
          <br />
          Copyright © 2021 <br />
          Raydius Private Limited
        </p>
      </form>
    </div>
  </div>
</template>
<script>
import SwiftAPI from "../../services/SwiftAPI";

export default {
  data() {
    return {
      user: {
        username: null,
        password: null,
        fcm: null,
      },
      error: null,
      require2fa: false,
      otp: null,
      loading: false,
    };
  },
  methods: {
    async login() {
      this.loading = true;
      SwiftAPI.login(this.user).then(
        (response) => {
          this.$store.dispatch("LOGIN_SUCCESS", response.data);
          this.$router.push({ name: "Dashboard" });
          this.loading = false;
        },
        (error) => {
          this.error = error.response.data;
          this.loading = false;
        }
      );
    },
  },
};
</script>
<style lang="css">
.login {
  height: 100vh;
  width: 100vw;
  background-image: url("https://imagedelivery.net/XhcLqhzn7vKozqHT_btVfg/16f8ddae-0472-4132-22a7-1fd65d864d00/public");
  background-repeat: no-repeat;
  background-size: cover;
}
.copyright {
  font-size: 12px;
  color: gray;
  text-align: center;
}
.primary {
  background: #14ca44;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 400px;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.4);
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  padding: 1rem;
}
</style>
