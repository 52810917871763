<template>
  <div>
    <div v-show="loading" class="spinner spinner-border" role="status"></div>
    <div class="d-flex justify-content-between">
      <div>
        <h3>
          {{ $route.name }}
          <span v-if="customer">
            - {{ customer.legalName ?? customer.name }} [{{
              customer.phoneNumber
            }}]</span
          >
        </h3>
      </div>
      <div>
        <button @click="paymentRequest" class="btn btn-primary">
          Request Payment
        </button>
      </div>
    </div>
    <div>
      <table v-if="!loading" class="table">
        <thead>
          <tr>
            <td>#</td>
            <td>Reference</td>
            <td>Time</td>
            <td>From</td>
            <td>Amount</td>
            <td>Receipt Status</td>
            <td class="text-end">Action</td>
          </tr>
        </thead>
        <tbody v-if="transactions">
          <TransactionComponent
            v-for="(transaction, index) in transactions"
            :key="index"
            :index="transactions.length - index"
            :data="transaction"
          ></TransactionComponent>
        </tbody>
      </table>
    </div>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import TransactionComponent from "../../components/TransactionComponent.vue";
import moment from "moment";
import SwiftAPI from "../../services/SwiftAPI";
export default {
  components: {
    TransactionComponent,
  },
  data() {
    return {
      transactions: null,
      loading: false,
      customer: null,
    };
  },
  async mounted() {
    this.loading = true;
    var customer = await SwiftAPI.getTransactionsOfCustomer(
      this.$route.params.id
    );
    this.customer = customer.data;
    this.transactions = customer.data.transactions;
    this.loading = false;
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
<style lang="css" scoped>
.customer-transactions {
  min-height: 87vh;
  padding: 0 10px;
}
.table {
  border-collapse: separate;
}
thead td {
  font-weight: bold;
}
</style>
