<template>
  <div class="dashboard">
    <div class="header d-flex justify-content-between">
      <div>
        <h3>Overview</h3>
      </div>
      <div></div>
    </div>
    <div class="col-md-6">
      <br />
      <h5>CRM (Customer Relationship Management)</h5>
      <p>
        CRM software (customer relationship management software) is a tool
        that's designed to help your organization offer your customers a unique
        and seamless experience, as well as build better relationships by
        providing a complete picture of all customer interactions, keeping track
        of your sales, organizing and prioritizing your opportunities, and
        facilitating collaboration between various teams.
      </p>

      <h5>Why do you need a CRM strategy?</h5>
      <p>
        Why do you need a CRM strategy? Without a proper CRM strategy, your
        customer information will be all over the place, and it'll be hard to
        keep track of all the touch points and interactions you've had with
        particular customers. This leads to a drawn out sales cycle, with a
        generic and sub-par customer experience.
      </p>

      <h5>Building great customer relations</h5>

      <p>
        Great customer relationships are based on the consistent and
        personalized experience offered by your organization, regardless of
        where a customer is in your sales cycle. This means that every team in
        your organization, from Marketing to Sales to Support must offer a
        consistent experience when interacting with a customer. When your
        business is starting off and you're managing all your customer
        information on spreadsheets, this may be viable, but not advisable. The
        amount of time spent on data entry could be better spent bringing in
        customers and closing deals. This becomes exponentially more complicated
        when you grow your business and expand your customer base. This is where
        CRM software comes into play.
      </p>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      transactions: null,
      loading: false,
    };
  },
  methods: {},
  async mounted() {},
  created: function () {
    this.moment = moment;
  },
};
</script>
<style lang="css" scoped></style>
