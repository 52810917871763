<template>
  <div
    class="d-none d-md-block sidebar"
    style="width: 4.5rem; height: 88%; margin-bottom: 20px"
  >
    <div
      class="sidebar d-flex flex-column flex-shrink-0 bg-light"
      style="width: 4.5rem"
    >
      <div class="logo"></div>
      <ul
        style="margin-top: 3rem"
        class="nav nav-pills nav-flush flex-column mb-auto text-center"
      >
        <li>
          <router-link class="nav-link py-3" to="/dashboard">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
              ></path>
            </svg>
          </router-link>
          <router-link class="nav-link py-3" to="/transactions">
            <svg
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 10h16M4 14h16M4 18h16"
              ></path>
            </svg>
          </router-link>
          <router-link class="nav-link py-3" to="/customer">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
              ></path>
            </svg>
          </router-link>
        </li>
      </ul>
      <div @click="logout" class="btn logout">
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          ></path>
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import SwiftAPI from "../services/SwiftAPI";
export default {
  data() {
    return {
      routes: [
        {
          path: "dashboard",
          name: "Dashboard",
        },
        {
          path: "transactions",
          name: "Transactions",
        },
        {
          path: "customer",
          name: "Customers",
        },
      ],
    };
  },
  methods: {
    logout() {
      SwiftAPI.logout().then(() => {
        this.$store.dispatch("LOGOUT");
        this.$router.push({ name: "Login" });
      });
    },
  },
};
</script>
<style lang="css" scoped>
.nav-link svg {
  width: 30px;
  height: 30px;
  background-size: contain;
}
.nav-link {
  height: 4rem;
  color: #727377;
}
.logo {
  background: url("https://imagedelivery.net/XhcLqhzn7vKozqHT_btVfg/1fd62697-b9b8-4ce8-9d7d-959e9e812a00/public")
    no-repeat;
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  min-height: 4rem;
  background-size: contain;
  margin: 5px;
}
.logout {
  height: 4rem;
  color: #727377;
}
.nav-link.active {
  background-color: red !important;
  color: white;
}
.sidebar {
  background: #ffffff !important;
  border-radius: 12px;
  margin-left: 1px;
  position: fixed;
  height: 98% !important;
  left: 0;
}
</style>
