<template>
  <div>
    <div class="header d-flex justify-content-between">
      <div>
        <h3>{{ $route.name }} - Today</h3>
      </div>
      <div>
        <button
          @click="paymentRequest"
          data-toggle="modal"
          data-target="#exampleModal"
          class="btn primary"
        >
          Request Payment
        </button>
      </div>
    </div>
    <div v-show="loading" class="spinner spinner-border" role="status"></div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    ></div>
    <div v-if="$route.name === 'Transactions'" class="transactions">
      <table v-if="!loading" class="table">
        <thead>
          <tr>
            <td>#</td>
            <td>Reference</td>
            <td>Time</td>
            <td>Customer</td>
            <td>From</td>
            <td>Amount</td>
            <td>Receipt Status</td>
            <td class="text-end">Action</td>
          </tr>
        </thead>
        <tbody v-if="$store.state.transactions">
          <TransactionComponent
            v-for="transaction in $store.state.new_transactions"
            :key="transaction.id"
            :index="transaction.id"
            :data="transaction"
          ></TransactionComponent>
          <TransactionComponent
            v-for="transaction in $store.state.transactions"
            :key="transaction.id"
            :index="transaction.id"
            :data="transaction"
          ></TransactionComponent>
        </tbody>
      </table>
    </div>

    <div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import TransactionComponent from "../components/TransactionComponent.vue";
import moment from "moment";
import SwiftAPI from "../services/SwiftAPI";
export default {
  props: {
    data: {
      type: Array,
      required: false,
    },
  },
  components: {
    TransactionComponent,
  },
  data() {
    return {
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    var transactions = await SwiftAPI.getTransactions();
    this.$store.dispatch("SET_TRANSACTIONS", transactions.data);
    this.loading = false;
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
<style lang="css" scoped>
.transactions {
  min-height: 87vh;
  padding: 0 10px;
}
.table {
  border-collapse: separate;
}
thead td {
  font-weight: bold;
}
</style>
