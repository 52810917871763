<template>
  <div class="customers">
    <div v-show="loading" class="spinner spinner-border" role="status"></div>
    <div
      v-if="$route.name === 'Customers'"
      class="d-flex justify-content-between"
    >
      <div>
        <h3>{{ $route.name }}</h3>
      </div>
      <div>
        <!-- <button @click="paymentRequest" class="btn btn-primary">
          Request Payment
        </button> -->
      </div>
    </div>
    <table v-if="!loading && $route.name === 'Customers'" class="table">
      <thead>
        <tr>
          <td>#</td>
          <td>Common Name</td>
          <td>Legal Name</td>
          <td>Phone</td>
          <td>IsAvailable</td>
          <td>Transaction Count</td>
          <td>Total Spent</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(customer, index) in customers" :key="customer.id">
          <td>{{ (index += 1) }}</td>
          <td>
            <router-link
              :to="{ name: 'Customer History', params: { id: customer.id } }"
              >{{ customer.name }}</router-link
            >
          </td>
          <td>{{ customer.legalName }}</td>
          <td>{{ customer.phoneNumber }}</td>
          <td>{{ customer.isSubscribed }}</td>
          <td>{{ customer.paymentCount }}</td>
          <td>{{ customer.total }}</td>
        </tr>
      </tbody>
    </table>
    <div>
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import SwiftAPI from "../services/SwiftAPI";
export default {
  data() {
    return {
      customers: null,
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    var result = await SwiftAPI.getCustomers();
    this.customers = result.data;
    this.loading = false;
  },
  created: function () {
    this.moment = moment;
  },
};
</script>
<style lang=""></style>
