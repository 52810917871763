import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    user: null,
    transactions: [],
    users: [],
  },
  getters: {
    getUser(state) {
      return state.user;
    },
  },
  mutations: {
    LOGIN_SUCCESS(state, payload) {
      state.user = payload;
    },
    LOGOUT(state) {
      state.user = null;
    },
    REFRESH_SUCCESS(state, payload) {
      state.user.refreshToken = payload.refreshToken;
    },
    SET_TRANSACTIONS(state, payload) {
      state.transactions = payload;
    },
    NEW_TRANSACTION(state, payload) {
      state.transactions.unshift(payload);
    },
  },
  actions: {
    SET_TRANSACTIONS({ commit }, payload) {
      commit("SET_TRANSACTIONS", payload);
    },
    NEW_TRANSACTION({ commit }, payload) {
      commit("NEW_TRANSACTION", payload);
    },
    LOGIN_SUCCESS({ commit }, payload) {
      commit("LOGIN_SUCCESS", payload);
    },
    REFRESH_SUCCESS({ commit }, payload) {
      commit("REFRESH_SUCCESS", payload);
    },
    LOGOUT({ commit }) {
      commit("LOGOUT");
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      key: "SwiftCRM Store",
      paths: ["user"],
    }),
  ],
});
