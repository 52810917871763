<template>
  <router-view />
</template>
<style>
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Quicksand:wght@300;400;500;600;700&display=swap");
body {
  font-family: "Comfortaa", cursive;
  font-family: "Quicksand", sans-serif;
}
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.btn {
  width: 100%;
}
.spinner {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.header {
  position: sticky !important;
  background: white;
  padding-top: 10px;
  top: 0;
}

.primary {
  background: #ec3237 !important;
  color: white !important;
  font-weight: bold !important;
}
</style>
