<template>
  <div v-if="transaction" class="details">
    {{ transaction.log }}
  </div>
</template>
<script>
import SwiftAPI from "../../services/SwiftAPI";
export default {
  data() {
    return {
      nos: this.index,
      transaction: this.data,
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    var transactions = await SwiftAPI.getTransaction(this.$route.params.id);
    this.transaction = transactions.data;
    this.loading = false;
  },
};
</script>
<style lang="css" scoped>
.details {
  min-height: 87vh;
  padding: 10px;
}
</style>
