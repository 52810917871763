import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

var firebaseConfig = {
  apiKey: "AIzaSyBsSf5AQd2lNoBGBFSWmuDohDJb9srxBMU",
  authDomain: "swift-crm-d703d.firebaseapp.com",
  projectId: "swift-crm-d703d",
  storageBucket: "swift-crm-d703d.appspot.com",
  messagingSenderId: "905467739686",
  appId: "1:905467739686:web:2fe9ecda1f2a3d8b13b0e7",
};

// eslint-disable-next-line no-undef
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export default { messaging, getToken, onMessage };
