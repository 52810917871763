<template>
  <div class="navbar d-flex d-md-none">
    <div>sd</div>
    <div class="logo"></div>
    <div></div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="css" scoped>
.navbar {
  position: static;
  top: 0;
  background: white;
  margin-bottom: 10px;
}

.logo {
  background: url("https://imagedelivery.net/XhcLqhzn7vKozqHT_btVfg/1fd62697-b9b8-4ce8-9d7d-959e9e812a00/public")
    no-repeat;
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  min-height: 3rem;
  background-size: contain;
}
</style>
