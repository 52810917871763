<template>
  <tr v-if="transaction">
    <td>{{ index }}</td>
    <td>{{ transaction.reference }}</td>
    <td>{{ transaction.transactionDate }}</td>
    <td v-if="transaction.customer">
      <router-link
        :to="{
          name: 'Customer History',
          params: { id: transaction.customer.id },
        }"
        >{{
          transaction.customer.legalName ?? transaction.customer.name
        }}</router-link
      >
    </td>
    <td>{{ transaction.from }}</td>
    <td>{{ transaction.amount }}</td>
    <td><status :status="transaction.transactionStatus" /></td>
    <td>
      <button
        v-if="transaction.transactionStatus !== 'Approved'"
        @click="verify"
        class="btn btn-primary"
        type="button"
        :disabled="loading"
      >
        <span
          v-show="loading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        <span v-show="!loading" class="sr-only">Verify</span>
      </button>
    </td>
  </tr>
</template>
<script>
import moment from "moment";
import Status from "../components/TransactionStatus.vue";
import SwiftAPI from "../services/SwiftAPI";
export default {
  components: { Status },
  props: {
    index: Number,
    data: Object,
  },
  data() {
    return {
      nos: this.index,
      transaction: this.data,
      loading: false,
    };
  },
  methods: {
    async verify() {
      this.loading = true;
      var result = await SwiftAPI.verifyTransaction(this.data.id);
      this.$notify({
        text: result.data.message,
      });
      this.transaction.transactionStatus = result.data.status;
      this.loading = false;
    },
  },
  computed: {},
  created: function () {
    this.moment = moment;
  },
};
</script>
<style lang="css" scoped>
.btn-verify {
  background: #0a95ff;
  font-weight: bold;
  color: white;
  height: 40px;
}
.reverse {
  background: black;
  color: white;
  font-weight: bold;
}
.transaction td {
  cursor: pointer;
}

table {
  border-collapse: collapse !important;
}
thead td {
  font-weight: bold;
}
</style>
