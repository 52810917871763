<template>
  <button v-if="status" class="btn status" :class="status">
    {{ status }}
  </button>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="css" scoped>
.status {
  width: 100px;
  border-radius: 12px;
}
.Approved {
  background: #e9f9eb;
  color: #0bbe28;
}

.Requested {
  background: #e9f9eb;
  color: #0bbe28;
}

.Failed {
  background: #ffeced;
  color: #ec1c24;
}

.Received {
  background: #dddddd;
  color: black;
}
</style>
